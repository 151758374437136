
import { defineComponent, reactive, ref, toRefs, onMounted } from "vue";
import type { FormInstance } from "element-plus";
import { ElMessage } from "element-plus";
import { Api } from "@/assets/Api";
import store from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Login",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  setup() {
    let data = reactive<any>({
      loginFormRef: ref<FormInstance>(),
      loginForm: {
        Save: false,
        UserName: "",
        Password: "",
        ClientType: "admin",
      },
      rules: {
        UserName: [
          {
            required: true,
            message: "请输入正确的健康云手机号，挂号后台请使用gh加原账号",
            trigger: "blur",
          },
        ],
        Password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
    });

    const router = useRouter();

    const login = (loginFormRef: FormInstance | undefined) => {
      (loginFormRef as FormInstance).validate((valid) => {
        if (!valid) return;
        Api.User.Login(data.loginForm).then((res: any) => {
          console.log(res);
          if (res.Code == 0) {
            if (data.loginForm.Save) {
              const info = {
                Save: data.loginForm.Save,
                UserName: data.loginForm.UserName,
              };
              store.dispatch("SetLoginForm", JSON.stringify(info));
            } else {
              store.dispatch("RemoveLoginForm");
            }
            store.dispatch("SetToken", res.Data.Token);
            store.dispatch("SetRoleInfo", res.Data.RoleInfo);
            store.dispatch("SetRole", res.Data.Role);
            store.dispatch("SetNickname", data.loginForm.UserName);
            router.push({
              path: "/main",
            });
          } else {
            ElMessage.warning({
              showClose: true,
              duration: 10000,
              message: res.ResultMsg,
            });
          }
        });
      });
    };

    onMounted(() => {
      store.dispatch("RemoveConfig");
      console.log("loginxxxx");

      const loginForm: string = store.getters.loginForm;
      if (loginForm) {
        var loginFormObj = JSON.parse(loginForm);
        data.loginForm.Save = loginFormObj.Save;
        data.loginForm.UserName = loginFormObj.UserName;
      }
    });

    return {
      ...toRefs(data),
      login,
    };
  },
});
